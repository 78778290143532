.vis-timeline {
  background-color: #212531;
  color: #fafafa;
  border-color: transparent;
}

.vis-item {
  border-color: #fafafa;
  background-color: #3b4049;
  font-size: 15pt;
  color: #fafafa;
}

.vis-item.vis-selected {
  background-color: #fafafa;
  color: #3b4049;
}

.vis-time-axis .vis-grid.vis-major .vis-grid.vis-minor {
  border-width: 3px;
  border-color: #fafafa;
}

.vis-text {
  color: #929395 !important;
}
